// CSS
import "./PostPage.css";

// Hooks
import { useState, useLayoutEffect } from "react";
import { useParams } from 'react-router-dom';

// Axios
import axios from "axios";

// Config
import CONFIG from "../../../config/config";

// Components
import { Loader, Alert } from "../../../components";

const PostPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState([]);
  const [notification, setNotification] = useState({});

  const { name } = useParams();

  useLayoutEffect(() => {
    const fetchData = async (url, type) => {
      try {
        const { data } = await axios.get(`${CONFIG.API_URL}${url}`, {
          headers: {
            Authorization : `Bearer ${localStorage.getItem("token")}`
          }	
        });
  
        if(type === "post") setPost(data);
        setIsLoading(false);
  
      } catch (error) {
        setNotification({title: "Erro", message: "Erro ao carregar o post", type: "danger"});
      }
    }

    fetchData(`/post/${name}`, "post");
  }, []);

  return (
    <>
      {notification.title && (
        <Alert title={notification.title} message={notification.message} type={notification.type} />
      )}
      <div className="container postContainer">
        {isLoading ? <Loader /> : (
          <div className="row d-flex justify-content-center flex-column align-items-center">
            <div className="header my-3">
              <h1 className="text-center m-0 p-0">{ post?.title }</h1>
              <p className="small-text text-center">{ post?.subtitle }</p>
            </div>

            {post?.title === "Pegar data atual" && (
              <div className="d-flex shadow rounded flex-column p-md-5">
                <h2>Código:</h2>

                <div className="col-12">
                  <iframe height="700" className="col-12" title="Pegar data atual com JS" src="https://codepen.io/guilhermeschuch/embed/Vwgjggw?default-tab=js%2Cresult&theme-id=dark" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
                    See the Pen <a href="https://codepen.io/guilhermeschuch/pen/Vwgjggw">
                    Pegar data atual com JS</a> by GuilhermeSchuch (<a href="https://codepen.io/guilhermeschuch">@guilhermeschuch</a>)
                    on <a href="https://codepen.io">CodePen</a>.
                  </iframe>
                </div>
              </div>
            )}

            {post?.title === "D3D Device being lost" && (
              <div className="col-md-7">
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <div className="imgContainer">                      
                    <img src="/img/blog/d3d.webp" className="img-fluid" />
                  </div>

                  <div className="mt-5">
                    <p>Um erro nada agradável e não muito comum que ocorre em jogos criados na Unreal Engine. Não sou nenhum profissional, mas se você está aqui, está passando pelo mesmo problema que passei.</p>
                    <p>Lendo em diversos fórums, vendo diversos vídeos de indianos e russos, resolvi resumir tudo em um post o conhecimento que adquiri.</p>
                    <p>Pelas informações que pude coletar, notei que esse erro ocorre pela falta de memória na sua placa de vídeo, provavelmente o jogo ficou mais pesado (ou menos otimizado) e sua placa não está dando conta.</p>
                  </div>
                </div>

                <div className="topics mt-5">
                  <h2><u>Tópicos:</u></h2>

                  <ul>
                    <li><a href="#driver" className="hover">Atualizar Drivers</a></li>
                    <li><a href="#boost" className="hover">NVIDIA Geforce Experience Battery Boost</a></li>
                    <li><a href="#integrity" className="hover">Verificar integridade dos arquivos</a></li>
                    <li><a href="#clean" className="hover">Instalação limpa dos Drivers</a></li>
                    <li><a href="#direct" className="hover">DirectX</a></li>
                    <li><a href="#full" className="hover">Jogo em tela cheia</a></li>
                    <li><a href="#tdr" className="hover">Configurando TdrDelay</a></li>
                  </ul>
                </div>

                <div className="content my-5">
                  <h2 id="driver">Atualizar Drivers</h2>

                  <p>Manter os Drivers do seu computador desatualizados pode influenciar no desempenho dos jogos e até mesmo causar problemas, use o <a href="https://www.iobit.com/pt/driver-booster.php" target="_blank">Driver Booster</a> ou o <a href="https://www.drivereasy.com/" target="_blank">Driver Easy</a> para fazer a instalação de todos os drivers necessários para o seu computador.</p>
                  <p>Você pode atualizar manualmente o Driver da placa de vídeo clicando, respectivamente, as teclas WIN + R no seu teclado e digitando <code>devmgmt.msc</code>, pressione enter após digitar.</p>

                  <img src="/img/blog/driver.webp" className="img-fluid" />

                  <p>Procure pelo seu driver de vídeo e clique em atualizar.</p>
                </div>

                <div className="content my-5">
                  <h2 id="boost">NVIDIA Geforce Experience Battery Boost</h2>

                  <p>Caso você esteja tentando rodar o jogo em um Notebook, é importante desativar todos os "boosts" que a NVIDIA aplica.</p>
                  <p>Certifique-se que o Geforce Experience Battery Boost esteja desativado. Você pode verificar através do próprio software da NVIDIA: Geforce Experience.</p>
                </div>

                <div className="content my-5">
                  <h2 id="integrity">Verificar integridade dos arquivos</h2>

                  <p>Se esse erro está ocorrendo em um jogo da Steam, tente verificar a integridade dos arquivos do jogo.</p>
                  <p>Clique com o botão direito no jogo -{'>'} Propriedades -{'>'} Arquivos Instalados -{'>'} Verificar Integridade dos arquivos do jogo. Caso o problema não seja resolvido, tente reinstalar o jogo.</p>

                  <img src="/img/blog/integrity.webp" className="img-fluid" />
                </div>

                <div className="content my-5">
                  <h2 id="clean">Instalação limpa dos Drivers</h2>

                  <p>Caso você tenha uma placa de vídeo da NVIDIA, saiba que muitos drivers desnecessários são instalados no seu PC, é recomendado, para esses drivers, utilizar o <a href="https://www.guru3d.com/download/display-driver-uninstaller-download/" target="_blank">DDU</a> para realizar a remoção dos drivers, e após a remoção, utilizar o <a href="https://www.techpowerup.com/download/techpowerup-nvcleanstall/">NVCleanstall</a> para instalar somente os drivers necessários.</p>
                </div>

                <div className="content my-5">
                  <h2 id="direct">DirectX</h2>

                  <p>É de suma importância, para rodar os jogos mais atuais, que você tenha instalado no seu computador a versão mais recente do DirectX, caso não tenha, pode realizar o download por <a href="https://www.microsoft.com/pt-br/download/details.aspx?id=35" target="_blank">aqui</a>.</p>
                </div>

                <div className="content my-5">
                  <h2 id="full">Jogo em tela cheia</h2>

                  <p>Por incrível que pareça, a solução do seu problema pode ser simplesmente trocar o modo de tela cheia para janela em seu jogo, e o erro não aparecerá novamente.</p>
                </div>

                <div className="content my-5">
                  <h2 id="tdr">Configurando TdrDelay</h2>

                  <p>Essa configuração basicamente irá especificar o número de segundos que a GPU pode atrasar a solicitação de preempção do agendador de GPU. (Não sei o que isso significa, mas para algumas pessoas essa configuração funcionou)</p>
                  <p>Primeiramente, você deve, respectivamente, pressionar as teclas WIN + R no seu teclado, e digitar: <code>regedit</code>, pressione enter.</p>
                  <p>Agora, você deve colar <code>HKEY_LOCAL_MACHINE\System\CurrentControlSet\Control\GraphicsDrivers</code> no campo como demonstra a imagem abaixo e pressionar enter.</p>
                  
                  <img src="/img/blog/tdr.webp" className="img-fluid" />

                  <p className="mt-5">Você deve criar uma nova DWORD clicando com o botão direito, deve nomeá-la como <code>TdrDelay</code>. Após nomeá-la, clique duas vezes com o botão esquerdo nela, e atribua o valor de 10 e a classifique como Decimal.</p>
                  
                  <img src="/img/blog/tdr2.webp" className="img-fluid" />
                </div>

                <div className="content my-5">
                  <h2>Conclusão</h2>

                  <p>Como mencionei no início do post, esse erro ocorre pois sua placa de vídeo não está suportando o jogo, caso nenhuma das soluções apresentadas funcionar para você, sinto muito, você enfrentou o mesmo destino que o meu. (Comprar uma placa de vídeo nova)</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default PostPage