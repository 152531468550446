// CSS
import "./PricingCard.css";

// Hooks
import { useEffect, useState } from "react";
import { convert } from "../../hooks/useConvertIsoDate";
import { formatToBrl } from "../../hooks/useToolsCases";

// MUI
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

const DenseTable = ({ data, type }) => {
  const date = new Date();

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead sx={{ backgroundColor: "#CECECE" }}>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Preço</TableCell>
            <TableCell>Expiração</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{item.name}</TableCell>
              <TableCell>{parseFloat(item.price).toFixed(2).replace('.', ',')}</TableCell>
              <TableCell>
                {Date.parse(item.expirationDate) < date ? <span className="expired">EXPIRADO</span> : convert(item.expirationDate)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ServicePopover = ({ data, type }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <span
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className="cursor-pointer"
      >
        {type === "host" && "Hospedagens: "}
        {type === "database" && "Banco de dados: "}
        {type === "extraService" && "Serviços extras: "}
      </span>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <DenseTable data={data} type={type} />
      </Popover>
    </>
  )
}

const PricingCard = ({ service, myServices }) => {
  const date = new Date();

  const [type, setType] = useState('');
  const [hostExpiration, setHostExpiration] = useState(null);
  const [databaseExpiration, setDatabaseExpiration] = useState(null);
  const [extraServiceExpiration, setExtraServiceExpiration] = useState(null);

  useEffect(() => {
    const total = service.total;

    if(total <= 100) {
      setType("bronze");
    }

    else if(total <= 200) {
      setType("silver");
    }

    else {
      setType("gold");
    }

    service.hosts.map((host) => {      
      const expiration = Date.parse(host.expirationDate);

      if(hostExpiration === null) {
        setHostExpiration(expiration);
      }
      else if(expiration < hostExpiration) {
        setHostExpiration(expiration);
      }
    })

    service.databases.map((database) => {      
      const expiration = Date.parse(database.expirationDate);

      if(databaseExpiration === null) {
        setDatabaseExpiration(expiration);
      }
      else if(expiration < databaseExpiration) {
        setDatabaseExpiration(expiration);
      }
    })

    service.extraServices.map((extraService) => {      
      const expiration = Date.parse(extraService.expirationDate);

      if(extraServiceExpiration === null) {
        setExtraServiceExpiration(expiration);
      }
      else if(expiration < extraServiceExpiration) {
        setExtraServiceExpiration(expiration);
      }
    })

    if(hostExpiration !== null && hostExpiration < Date.parse(date)) {
      setHostExpiration(null);
    }

    if(databaseExpiration !== null && databaseExpiration < Date.parse(date)) {
      setDatabaseExpiration(null);
    }

    if(extraServiceExpiration !== null && extraServiceExpiration < Date.parse(date)) {
      setExtraServiceExpiration(null);
    }

  }, [service])

  return (
    <>
      <div className="card-container text-center">
        <div className={`pricing-card ${type}`} key={service?._id}>
          <img src={`/img/medals/${type}-medal.webp`} alt="Card Image" width="200px" />

          <h2>{ service?.name }</h2>

          <p className="fw-bold">
            <ServicePopover data={service.hosts} type="host" />
            <span>{formatToBrl(parseFloat(service.totalHosts))}</span>
            <span className="d-block small-text fw-normal">
              Próxima expiração: { hostExpiration ? convert(hostExpiration) : <span className="expired">EXPIRADO</span> }
            </span>
          </p>

          <p className="fw-bold">
            <ServicePopover data={service.databases} type="database" />
            <span>{formatToBrl(parseFloat(service.totalDatabases))}</span>
            <span className="d-block small-text fw-normal">
              Próxima expiração: { databaseExpiration ? convert(databaseExpiration) : <span className="expired">EXPIRADO</span> }
            </span>
          </p>

          <p className="fw-bold">
            <ServicePopover data={service.extraServices} type="extraService" />
            <span className="fw-bold">{formatToBrl(parseFloat(service.totalExtraServices))}</span>
            <span className="d-block small-text fw-normal">
              Próxima expiração: { extraServiceExpiration ? convert(extraServiceExpiration) : <span className="expired">EXPIRADO</span> }
            </span>
          </p>

          <p className={`price ${type}-price`}>{formatToBrl(parseFloat(service.totalService))}</p>

          { myServices ? (
            <button
              className={`pricing-button ${type} btn-${type}`}
              onClick={() => window.open("https://wa.link/p83z2w")}
            >
              Pagar
            </button>
          ) : '' }
        </div>
      </div>
    </>
  )
}

export default PricingCard