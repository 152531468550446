// Router
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Notifications
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'

// Video Player
import "../node_modules/video-react/dist/video-react.css";

// Hooks
import { usePreventContextmenu } from "./hooks/usePreventContextmenu";
import { useCheckSession } from "./hooks/useCheckSession";

/*
  TODO:
  - Add more images to blog post and explain things better
  - Routes protection
  - Header auth if is admin
  - Verify if localstorage token === backend token
  - Stretch blog to the middle in some cases
  - Pesquisar imagens medals
*/

// Pages
import {
  HomePage,
  LoginPage,
  CreateUserPage,
  PricingPage,
  LinkServicesPage,
  MyServicesPage,
  ProfilePage,
  ProjectPage,
  NotFoundPage,
  HomeBlogPage,
  PostPage,
  CreateServicePage
} from "./pages";

// Components
import { Header, Footer } from "./components";


function App() {
  useCheckSession();
  usePreventContextmenu();

  return (
    <div className="App">
      <ReactNotifications />
      <BrowserRouter>
        <Header />

        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/createuser" element={<CreateUserPage/>} />
          <Route path="/pricing/:name" element={<PricingPage/>} />
          <Route path="/linkservices" element={<LinkServicesPage/>} />
          <Route path="/myservices" element={<MyServicesPage/>} />
          <Route path="/profile" element={<ProfilePage/>} />
          <Route path="/project/:name" element={<ProjectPage/>} />
          <Route path="/blog" element={<HomeBlogPage/>} />
          <Route path="/blog/post/:name" element={<PostPage/>} />
          <Route path="/createservice" element={<CreateServicePage/>} />
          <Route path='*' element={<NotFoundPage/>} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
