// Lazy Loading
import { LazyLoadImage } from "react-lazy-load-image-component";

const Post = ({ posts }) => {
  return (
    <>
      {posts?.map((post, index) => (
        <div key={index} className="col-md-6">
          <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
              <strong className="d-inline-block mb-2 text-primary-emphasis">{ post?.tag }</strong>
              <h3 className="mb-0">{ post?.title }</h3>
              <div className="mb-1 text-body-secondary">{ post?.date }</div>
              <p className="card-text mb-auto">{ post?.subtitle }</p>
              <a href={`/blog/post/${post?.title}`} className="icon-link gap-1 icon-link-hover stretched-link">
                Ver mais
              </a>
            </div>
            <div className="col-auto d-none d-lg-block">
              {post?.title === "Pegar data atual" && (
                <LazyLoadImage
                  src="https://images.unsplash.com/photo-1619250556999-38af9033f9d4?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzN8fGphdmFzY3JpcHR8ZW58MHwxfDB8fHww"
                  width={200}
                  height={250}
                  alt=""
                  effect="blur"
                  placeholderSrc="https://placehold.jp/cccccc/cccccc/200x250.png"
                />
              )}
              
              {post?.title === "D3D Device being lost" && (
                <LazyLoadImage
                  src="https://images.unsplash.com/photo-1660165088018-5a47b88ffcf8?auto=format&fit=crop&q=80&w=1528&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  width={200}
                  height={250}
                  alt=""
                  effect="blur"
                  placeholderSrc="https://placehold.jp/cccccc/cccccc/200x250.png"
                />
              )}
            </div>
          </div>
        </div> 
      ))}
    </>
  )
}

export default Post