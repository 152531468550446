// CSS
import "./Footer.css";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="container footer-container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 mt-4 border-top">
        <div className="col-md-4 d-flex align-items-center">
          <img src="/img/logo/logo-dark-cut.webp" alt="" className="bi" width="50" height="50" />
          <span className=" ms-1 text-body-secondary">Guilherme Schuch © { year }</span>
        </div>
    
        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3"><a className="text-body-secondary" href="https://www.instagram.com/guigui.schuch/" target="_blank"><img src="/img/icons/instagram-167-svgrepo-com.svg" className="bi" width="24" height="24" alt="" /></a></li>
          <li className="ms-3"><a className="text-body-secondary" href="https://github.com/GuilhermeSchuch" target="_blank"><img src="/img/icons/github-142-svgrepo-com.svg" className="bi" width="24" height="24" alt="" /></a></li>
          <li className="ms-3"><a className="text-body-secondary" href="https://www.linkedin.com/in/guilhermeschuch2005/" target="_blank"><img src="/img/icons/linkedin-161-svgrepo-com.svg" className="bi" width="24" height="24" alt="" /></a></li>
        </ul>
      </footer>
    </div>
  )
}

export default Footer