// CSS
import "./Profile.css";

// Axios
import axios from "axios";

// Config
import CONFIG from "../../config/config";

// Hooks
import { useState, useLayoutEffect } from "react";

// Components
import { Alert } from "../../components";

const Profile = () => {
  const [userData, setUserData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [notification, setNotification] = useState({})
  const [user, setUser] = useState({
    name: '',
    password: ''
  })

  const email = localStorage.getItem('email');
  const token = localStorage.getItem('token');

  useLayoutEffect(() => {
    const fetchData = async (url, type) => {
      try {
        const { data } = await axios.get(`${CONFIG.API_URL}${url}`, {
          headers: {
            Authorization : `Bearer ${localStorage.getItem("token")}`
          }	
        });
  
        if(type === "user") setUserData(data);
        if(userData.name) setUser({ name: userData.name });
  
      } catch (error) {
        setNotification({title: "Erro", message: "Erro ao buscar dados", type: "danger"});
      }
    }

    fetchData(`/user/${email}`, "user");
    setNotification({});
  }, []);

  const handleSubmit = (e) => {
		e.preventDefault();

    setShowAlert(false);
    setIsButtonDisabled(true);
    setNotification({});

		axios({
			method: 'PUT',
			url: `${CONFIG.API_URL}/user/${userData._id}`,
			data: { user },
      headers: { Authorization: 'Bearer ' + token }
    })
    .then((res) => {
			if(res.status === 200 && !res.data.error) {
        setNotification({title: "Dados atualizados!", message: res.data.msg, type: "success"});
			}
		})
    .catch((err) => {
      const error = err.response.data.error;
      setNotification({title: "Erro", message: error, type: "danger"});
    })

    setIsButtonDisabled(false);
	}

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch(name) {
      case "name":
        setUser({ name: value, password: user.password });
        break;

      case "password":
        setUser({ name: user.name, password: value });
        break;
    
      default:
        break;
    }
  }

  return (
    <>
      {notification.title && (
        <Alert title={notification.title} message={notification.message} type={notification.type} />
      )}
      <div className="configContainer container d-flex justify-content-center">
        <form id="edit-form" className="shadow rounded p-4 col-12 col-md-4 mt-3" onSubmit={handleSubmit}>
          <h1 className="text-center">Atualizar dados</h1>          

          {userData.name && (
            <>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Nome:</label>
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  id="name"
                  name="name"
                  placeholder={userData.name}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">Senha:</label>
                <input
                  type="password"
                  className="form-control"
                  autoComplete="off"
                  id="password"
                  name="password"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </>
          )}

          <button
            type="submit"
            className="btn btn-primary w-100 py-2" 
            disabled={isButtonDisabled}>{ !isButtonDisabled ? 'Atualizar' : 'Carregando...' }
          </button>
        </form>
      </div>
    </>
  );
};


export default Profile